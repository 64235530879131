export class Utility {
  constructor() {
    if (typeof Utility.instace === 'object') {
      return Utility.instace;
    }
    Utility.instace = this;
    return this;
  }

  getGlueProperties(baseType, resultObject) {
    return resultObject.properties.filter((property) => {
      return !!baseType.glueProperties.find((glueProperty) => glueProperty === property.name);
    });
  }

  getExtendedProperties(baseType, resultObject) {
    return resultObject.properties.filter((property) => {
      return !baseType.glueProperties.find((glueProperty) => glueProperty === property.name);
    });
  }

  generateHash(properties) {
    const basehash = properties.reduce((previousValue, currentValue) => {
      return String(previousValue).trim() + String(currentValue.value).trim();
    }, '');
    const buff = Buffer.from(basehash);
    const base64data = buff.toString('base64');
    return base64data;
  }

  join(baseType, queryObjects) {
    const result = [];
    queryObjects.forEach((queryObject) => {
      queryObject.resultObjects.forEach((resultObject) => {
        // if (resultObject.valid) {
        const glueProperties = this.getGlueProperties(baseType, resultObject);
        const extendedProperties = this.getExtendedProperties(baseType, resultObject);
        const identifier = this.generateHash(glueProperties);

        let merged = result.find((item) => item.identifier === identifier);

        const extendedProperty = {};
        extendedProperty.srcName = queryObject.objectType.name;
        extendedProperty.properties = extendedProperties;

        if (merged) {
          merged.extendedData.push(extendedProperty);
        } else {
          merged = {};
          merged.baseProperties = glueProperties;
          merged.extendedData = [extendedProperty];
          merged.identifier = identifier;

          result.push(merged);
        }
        // }
      });
    });
    return result;
  }
}
