import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import MapType from './MapType.vue'
import store from '../../store'

const MapTypeController = class {
  constructor(propsData) {
    Object.setPrototypeOf(this, window.ol.control.Control.prototype)
    const Constructor = Vue.extend(MapType)
    this.component = new Constructor({
      vuetify,
      store,
      propsData
    }).$mount()

    window.ol.control.Control.call(this, {
      element: this.component.$el
    });
  }
}

export default MapTypeController
