import Vue from 'vue'
import store from '@/store';
import vuetify from '@/plugins/vuetify'
import RightPanel from './RightPanel.vue'

const RightPanelController = class {
  constructor(propsData) {
    Object.setPrototypeOf(this, window.ol.control.Control.prototype)
    const Constructor = Vue.extend(RightPanel)
    this.component = new Constructor({
      store,
      vuetify,
      propsData
    }).$mount()

    window.ol.control.Control.call(this, {
      element: this.component.$el
    });
  }
}

export default RightPanelController
