<template>
  <transition name="slide-fade">
    <div class="left-panel d-flex flex-column" v-hotkey="keymap" v-show="show">
      <murphy class="mb-2" style="max-height: 410px;"></murphy>
      <!-- <chat-sos></chat-sos> -->
      <div>
        <v-img id="rosavientos" style="object-fit: cover; width: 200px; height: 200px;"
          :src="require('@/assets/rosavientos.png')" class="white--text align-end"></v-img>
      </div>
    </div>
  </transition>
</template>
<script>

import Murphy from '../murphy/Murphy.vue'
// import ChatSos from './chatSos.vue'

export default {
  components: {
    murphy: Murphy,
    // chatSos: ChatSos
  },
  props: {
    devicesCounter: Object,
    camerasCounter: Object,
    incidentCategoryCounter: Object,
    incidentsStore: Object,
    devicesStore: Object
  },
  data() {
    return {
      show: true,
    }
  },
  methods: {
    toggle() {
      this.show = !this.show
    },
  },
  computed: {
    keymap() {
      return {
        'ctrl+space': this.toggle
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.left-panel {
  position: absolute;
  left: var(--map-widget-padding);
  bottom: calc(var(--map-widget-padding) * 2 + 52px);
  height: calc(100vh - 95px - 2em - 52px);
  min-width: 400px;
  max-width: 600px;
  justify-content: space-between;
  pointer-events: none !important;
}
</style>
